<template>
  <main-layout>
    <content id="contact-page" class="d-block bg-light">
      <header
        class="contact-header text-center d-flex justify-content-center flex-column"
      >
        <h1>İletişim</h1>
        <hr />
        <nav class="bg-transparent" aria-label="breadcrumb">
          <ol
            class="contact-breadcrumb breadcrumb bg-transparent text-white d-inline-flex p-0 m-0"
          >
            <router-link
              tag="li"
              class="contact-link breadcrumb-item cursor-pointer"
              to="/"
            >
              <a class="icon-home"></a>
            </router-link>
            <li class="contact-link breadcrumb-item" aria-current="page">
              İletişim
            </li>
          </ol>
        </nav>
      </header>
      <main class="container full py-5">
        <h2 class="contact-title">İletişim Bilgileri</h2>
        <div class="contact">
          <ul class="pl-0">
            <li>
              Doğuş Otomotiv ve Servis ve Ticaret<br />
              <i class=""
                ><svg class="adress-svg" width="12px" height="16px">
                  <path
                    fill-rule="evenodd"
                    fill="rgb(81, 81, 82)"
                    d="M6.000,16.000 C5.030,16.000 -0.000,9.314 -0.000,6.000 C-0.000,2.686 2.686,0.000 6.000,0.000 C9.314,0.000 12.000,2.686 12.000,6.000 C12.000,9.314 6.939,16.000 6.000,16.000 ZM6.000,2.000 C3.791,2.000 2.000,3.791 2.000,6.000 C2.000,8.209 3.791,10.000 6.000,10.000 C8.209,10.000 10.000,8.209 10.000,6.000 C10.000,3.791 8.209,2.000 6.000,2.000 Z"
                  ></path></svg
              ></i>
              Şekerpınar Mah. Anadolu Cad. No:22 ve 45, PK:41420
              Çayırova/KOCAELİ
            </li>
            <li style="width: 38%">
              <i
                ><svg
                  version="1.1"
                  id="phone-svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 438.5 438.5"
                  style="enable-background: new 0 0 438.5 438.5"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M414.4,24.1C398.3,8,379,0,356.3,0H82.2c-22.6,0-42,8-58.1,24.1C8,40.2,0,59.6,0,82.2v274.1c0,22.6,8,42,24.1,58.1		c16.1,16.1,35.5,24.1,58.1,24.1h274.1c22.6,0,42-8,58.1-24.1c16.1-16.1,24.1-35.5,24.1-58.1V82.2C438.5,59.6,430.5,40.2,414.4,24.1		z M359.2,332.9c-4,8.8-13,16.4-27,22.8c-14,6.5-26.4,9.7-37.3,9.7c-3,0-6.3-0.2-9.7-0.7c-3.4-0.5-6.3-1-8.7-1.4		c-2.4-0.5-5.5-1.3-9.4-2.6c-3.9-1.2-6.7-2.2-8.4-2.9c-1.7-0.7-4.9-1.9-9.4-3.6c-4.6-1.7-7.4-2.8-8.6-3.1		c-31.2-11.4-61.7-32-91.5-61.8c-29.8-29.8-50.4-60.3-61.8-91.5c-0.4-1.1-1.4-4-3.1-8.6c-1.7-4.6-2.9-7.7-3.6-9.4	c-0.7-1.7-1.6-4.5-2.9-8.4c-1.2-3.9-2.1-7-2.6-9.4c-0.5-2.4-0.9-5.3-1.4-8.7c-0.5-3.4-0.7-6.7-0.7-9.7c0-10.9,3.2-23.3,9.7-37.3		c6.5-14,14.1-23,22.8-27c10.1-4.2,19.7-6.3,28.8-6.3c2.1,0,3.6,0.2,4.6,0.6c1,0.4,2.5,2.1,4.7,5.1s4.6,6.9,7.1,11.6		c2.6,4.7,5.1,9.2,7.6,13.6c2.5,4.4,4.9,8.7,7.1,13c2.3,4.3,3.7,7,4.3,8.1c0.6,1,1.8,2.8,3.7,5.4c1.9,2.7,3.3,5,4.3,7.1		c0.9,2.1,1.4,4.1,1.4,6c0,2.9-2,6.3-5.9,10.4c-3.9,4.1-8.2,7.9-12.9,11.3s-8.9,7.1-12.8,11c-3.9,3.9-5.9,7.1-5.9,9.6		c0,1.3,0.3,2.9,1,4.7c0.7,1.8,1.3,3.3,1.9,4.4c0.6,1.1,1.5,2.8,2.7,4.9c1.2,2.1,2,3.4,2.4,4c10.5,18.8,22.5,35.1,36.1,48.7		c13.6,13.6,29.8,25.6,48.7,36.1c0.6,0.4,1.9,1.2,4,2.4c2.1,1.2,3.7,2.1,4.9,2.7c1.1,0.6,2.6,1.2,4.4,1.9c1.8,0.7,3.4,1,4.7,1		c3,0,7.2-3.1,12.6-9.4c5.3-6.3,10.8-12.5,16.3-18.7c5.5-6.2,10-9.3,13.4-9.3c1.9,0,3.9,0.5,6,1.4c2.1,1,4.5,2.4,7.1,4.3		c2.7,1.9,4.5,3.1,5.4,3.7l15.1,8.3c10.1,5.3,18.5,10,25.3,14.1s10.4,6.9,11,8.4c0.4,1,0.6,2.5,0.6,4.6		C365.4,313.2,363.4,322.8,359.2,332.9z"
                    ></path>
                  </g></svg
              ></i>
              <a href="tel:(0262) 676 90 90">(0262) 676 90 90</a>
            </li>
            <li style="width: 62%">
              <i
                ><svg
                  version="1.1"
                  class="mail-svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 511.6 402"
                  style="enable-background: new 0 0 511.6 402"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M49.1,123.9c6.5,4.6,26,18.1,58.5,40.7c32.5,22.6,57.5,39.9,74.8,52.1c1.9,1.3,5.9,4.2,12.1,8.7			c6.2,4.5,11.3,8.1,15.4,10.9c4.1,2.8,9,5.9,14.8,9.3c5.8,3.4,11.3,6,16.4,7.7c5.1,1.7,9.9,2.6,14.3,2.6h0.3h0.3			c4.4,0,9.1-0.9,14.3-2.6c5.1-1.7,10.6-4.3,16.4-7.7c5.8-3.4,10.8-6.5,14.8-9.3c4.1-2.8,9.2-6.4,15.4-10.9			c6.2-4.5,10.2-7.4,12.1-8.7c17.5-12.2,62.1-43.1,133.6-92.8c13.9-9.7,25.5-21.4,34.8-35.1c9.3-13.7,14-28.1,14-43.1			c0-12.6-4.5-23.3-13.6-32.3C489,4.5,478.3,0,465.9,0H45.7C31,0,19.7,4.9,11.8,14.8C3.9,24.7,0,37.1,0,52c0,12,5.2,25,15.7,39			C26.2,104.9,37.3,115.9,49.1,123.9z"
                      ></path>
                      <path
                        d="M483.1,154.5c-62.4,42.3-109.8,75.1-142.2,98.5c-10.8,8-19.6,14.2-26.4,18.7c-6.8,4.5-15.7,9-27,13.7			c-11.2,4.7-21.7,7-31.4,7h-0.3h-0.3c-9.7,0-20.2-2.3-31.4-7c-11.2-4.7-20.2-9.2-27-13.7c-6.8-4.5-15.6-10.7-26.4-18.7			c-25.7-18.8-73-51.7-141.9-98.5C18,147.2,8.4,138.9,0,129.6v226.7c0,12.6,4.5,23.3,13.4,32.3c8.9,8.9,19.7,13.4,32.3,13.4h420.3			c12.6,0,23.3-4.5,32.3-13.4c8.9-8.9,13.4-19.7,13.4-32.3V129.6C503.4,138.7,493.9,147,483.1,154.5z"
                      ></path>
                    </g>
                  </g></svg
              ></i>
              <a
                href="mailto:DosSurecYonetimi@dogusotomotiv.com.tr"
                style="width: 100%"
                >DosSurecYonetimi@dogusotomotiv.com.tr</a
              >
            </li>
          </ul>
          <h2 class="contact-title">Şikayet ve Öneri Bildirimi</h2>
          <form>
            <div class="form-group">
              <input
                class="form-control"
                disabled
                id="txtFullName"
                name="FullName"
                placeholder="İsim Soyisim"
                type="text"
                validationelement="1"
                v-model="notify.fullName"
              />
            </div>
            <div
              id="txtFullNameError"
              class="form-group invalid-feedback"
              style="display: none"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <input
                class="form-control"
                disabled
                id="txtEmail"
                name="Email"
                placeholder="E-Posta"
                type="text"
                validationelement="1"
                v-model="notify.email"
              />
            </div>
            <div
              id="txtEmailError"
              class="form-group invalid-feedback"
              style="display: none"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <input
                class="form-control"
                id="txtPhoneNumber"
                name="PhoneNumber"
                placeholder="Telefon Numarası"
                type="text"
                validationelement="1"
                v-model="notify.phoneNumber"
              />
            </div>
            <div
              id="txtPhoneNumberError"
              class="form-group invalid-feedback"
              style="display: none"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group">
              <input
                class="form-control"
                id="txtDemand"
                name="Demand"
                placeholder="Lütfen şikayet ve öneri talebinizi giriniz"
                type="text"
                v-model="notify.demand"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control"
                id="txtComplaintDetail"
                name="ComplaintDetail"
                placeholder="Şikayetin detayı (Olayın gelişimi ve etkisi)"
                type="text"
                v-model="notify.complaintDetail"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control"
                id="txtMessage"
                name="Message"
                placeholder="Mesajınız"
                type="text"
                v-model="notify.message"
              />
            </div>

            <div class="form-group text-center">
              <button
                type="button"
                class="btn-innovation-modal bg-orange"
                @click="createNotify"
              >
                GÖNDER<span class="icon-arrow-right pl-2"></span>
              </button>
            </div>
          </form>
          <div class="row contact"></div>
        </div>
      </main>
    </content>
  </main-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainLayout from "../layouts/MainLayout.vue";
export default {
  name: "ContactUs",
  data() {
    return {
      notify: {
        fullName: "",
        email: "",
        phoneNumber: "",
        demand: "",
        complaintDetail: "",
        message: "",
      },
    };
  },
  components: {
    MainLayout,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  methods: {
    ...mapActions({
      createNotifyVuex: "suggestion/createNotify",
    }),
    async createNotify() {
      const body = {
        CreatedCustomerId: this.user.Data.UserId,
        CompanyId: this.user.Data.CompanyId,
        TelephoneNumber: this.notify.phoneNumber,
        Demand: this.notify.demand,
        ComplaintDetail: this.notify.complaintDetail,
        Message: this.notify.message,
      };
      if (this.notify.phoneNumber) {
        const res = await this.createNotifyVuex(body);
        if (res.Success) {
          this.$toast.success(res.Message, {
            position: "top",
          });
        } else {
          this.$toast.error(res.Message, {
            position: "top",
          });
        }
      } else {
        this.$toast.error("Lütfen telefon numaranızı giriniz!", {
          position: "top",
        });
      }
    },
  },
  created() {
    if (this.user) {
      this.notify.fullName = this.user.Data.FullName;
      this.notify.email = this.user.Data.UserName;
    }
  },
};
</script>

<style></style>
